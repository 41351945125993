<template>
  <b-modal size="md" scrollable :id="'modal-housing' + contract.id" :title="'Contrato '+contract.id" ok-only hide-footer >
    <b-card header-tag="header">
      <template #header>
        <h4 class="mb-0">Viviendas de {{contract.name}} {{contract.lastname}}</h4>
      </template>
      <div v-if="houses.length">
        <span v-for="house in houses" :key="house.codeHousing">
          <hr>
          <h5>{{house.fullname}}</h5>
          <span v-if="house.relation == '1'"><label><i>Propietario principal</i></label><br></span>
          <label>{{house.nameHousing}}</label>
        </span>
      </div>
    </b-card>
  </b-modal>
</template>

<script>

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    houses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'housingname', label: 'Viviendas' },
      ],
    }
  }
};
</script>
