<template>
  <b-card>
    <ListCustomers />
  </b-card>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"

// import Customers from '@/modules/fivesClub/components/catalogs/customers/Customers'
import ListCustomers from '@/modules/fivesClub/components/catalogs/customers/ListCustomers'
// import CustomersCreate from '@/modules/fivesClub/components/catalogs/customers/CustomersCreate'
import { acl } from "@/modules/auth/mixins/acl"


export default {
  mixins: [acl],
  components:{
    // Customers
    ListCustomers,
    // CustomersCreate,
  },
  async mounted() {
    await this.getInitialContentOwners({
      hotels: !this.hotels?.length > 0,
      languages: !this.languages?.length > 0,
      nationalities: !this.nationalities?.length > 0,
    }) // aqui pido hotels, languages y nationalities

  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs', ["nationalities"]),
    ...mapState("start", ["languages", "hotels"]),
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['getInitialContentOwners']),
    ...mapMutations('fivesClubCatalogs',['setSelectedOwner','setSelectedOwnerPassword']),
    setTabIndex( index ){
      this.tabIndex = index
    },
    clickTab(){
      this.setSelectedOwner(null)
      this.setSelectedOwnerPassword(null)
    },
    clickTabResetEdit(index){
      this.tabIndex = index
      this.clickTab()
    },
  },
}
</script>
