<template>
    <div>
        <b-table
            :items="owners"
            :fields="tableColumns"
            :tbody-tr-class="rowClass"
            :filter="porOwner"
            :filter-included-fields="filterOn"
            primary-key="id"
            :sort-by.sync="isSortBy"
            show-empty
            responsive
            empty-text="No matching records found"
            :sort-desc.sync="isSortDir"
            class="position-relative mb-0"
            v-if="owners"
        >

            <template #cell()="data">
                <span class="text-nowrap">
                    {{ data.value}}
                </span>
            </template>

            <template #cell(status)="row">
                <span class="text-nowrap">
                    <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                    {{ row.item.status ? "Activo" : "Desactivado" }}
                    </b-badge>
                </span>
            </template>

            <!-- actions de edicion está ligado a Status -->
            <template #cell(actions)="row">
                <div class="text-nowrap">
                    <b-button
                        v-b-tooltip.hover
                        title="Ver viviendas del propietario"
                        size="sm"
                        @click="showModalOwner(row.item)"
                        variant="link"
                        :class="{ 'd-none': !row.item.status || row.item.isLoading }"
                        class="mr-0"
                    >
                        <feather-icon icon="HomeIcon" size="14" />
                    </b-button>
                    <ModalHousing :contract="row.item" :houses="houses" />
                    <b-button
                        size="sm"
                        @click="setOwnerToEdit(row.item)"
                        variant="primary"
                        :class="{ 'd-none': !row.item.status || row.item.isLoading }"
                        class="mr-1"
                    > <feather-icon icon="Edit2Icon" size="12" />
                    </b-button>
                    <b-button
                        v-can="'fivesclub_owners_show_owners_list_action_button_change_password'"
                        size="sm"
                        @click="setOwnerToEditPassword(row.item)"
                        variant="warning"
                        v-b-tooltip.hover.v-warning
                        title="Cambiar contraseña"
                        :class="{ 'd-none': !row.item.status || row.item.isLoading }"
                    > <feather-icon icon="LockIcon" size="12" />
                    </b-button>
                    <b-modal
                        v-can="'fivesclub_owners_show_owners_list_action_button_change_password'"
                        :title="'Nueva contraseña para '+row.item.name+ ' ' +row.item.lastname"
                        :id="'change-password-modal'+row.item.id"
                        :hide-footer="true"
                        no-close-on-backdrop
                    >
                        <CustomersEditPassword v-can="'fivesclub_owners_show_owners_list_action_button_change_password'"/>
                    </b-modal>
                    <b-form-checkbox
                        :class="{ 'd-none': row.item.status }"
                        v-model="row.item.status"
                        v-b-tooltip.hover.v-warning
                        title="Cambiar Status"
                        :value="true"
                        switch
                        inline
                        :unchecked-value="false"
                        @change="changeStatus(row.item, $event)"
                        class="custom-control-success"
                    >
                    </b-form-checkbox>
                    <b-spinner label="Loading..." variant="success" v-if="row.item.isLoading"/>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage, toJson } from '@/helpers/helpers'
import ModalHousing from '@/modules/fivesClub/components/catalogs/customers/ModalHousing'
import CustomersEditPassword from '@/modules/fivesClub/components/catalogs/customers/CustomersEditPassword'

export default {
    mixins: [utils],
    components: {
        CustomersEditPassword,
        ModalHousing
    },
    props: {
        owners: {
            type: Array,
            required: false,
        },
        tableColumns: {
            type: Array,
            required: true,
        },
        sortBy: {
            type: String,
            required: true,
        },
        isSortDirDesc: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isSortDir: this.isSortDirDesc,
            isSortBy: this.sortBy,
            filterOn: [],
            houses: []
        };
    },
    created() {
        this.filtered();
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("fivesClubCatalogs", ["filter"]),
        porOwner() {
            if (this.filter.query !== "") return this.filter.queryOwners;
            else this.filter.queryOwners = "";
        }
    },
    methods: {
        ...mapActions("fivesClubCatalogs", ["updateStatusOwner"]),
        ...mapActions("fivesClubContracts", ["fetchInfoOwnerOrHousing"]),
        ...mapMutations("fivesClubCatalogs", ["setSelectedOwner", "setSelectedOwnerPassword"]),
        setOwnerToEdit(rowData) {
            const data = toJson(rowData)
            // this.$emit("set-tab-index", 2);
			this.$router.push({ name: "customers-edit", params: { idOwner: data.id } })
        },
        setOwnerToEditPassword(data) {
            // this.$emit("set-tab-index", 3);
            this.setSelectedOwnerPassword(data)
            this.$root.$emit("bv::show::modal", "change-password-modal" + data.id);
        },
        async changeStatus(item, event) {
            item.isLoading = true;
            const payload = {
                idOwner: item.id,
                idUser: this.user.idUser,
                status: Boolean(event),
            };
            const { status, message } = await this.updateStatusOwner(payload);
            item.isLoading = false;
            if (status) showAlertMessage("Ok: Status actualizado", "InfoIcon", message, "success", 4000, "bottom-right")
        },
        filtered() {
            this.filterOn = ["name", "lastname", "phone", "email"];
        },
        async showModalOwner(data) {
            this.houses = await this.fetchInfoOwnerOrHousing({ idOwner: data.id });
            this.$root.$emit("bv::show::modal", "modal-housing" + data.id);
        },
    },
}
</script>
<style>
.isCentered{
    align-content: center;
    align-items: center;
}
.isSpinner{
    padding: 2rem;
}
</style>