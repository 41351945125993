<template>
  <validation-observer ref="formChangePassword" tag="form" v-slot="{ invalid }">
    <b-form @submit.prevent="handlerChangePassword">
      <b-row>
        <b-col md="6">
          <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="new-password">Nueva contraseña</label>
          </div>
            <validation-provider #default="{ errors }" name="new password" rules="required|min:6" vid="password">
              <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
                <b-form-input
                  id="new-password"
                  v-model="newPassword"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldTypeNew"
                  placeholder="*********"
                />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="passwordToggleIconNew" @click="togglePasswordNew"
                />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="confirm-password">Confirmar nueva contraseña</label>
            </div>
            <validation-provider #default="{ errors }" name="confirm password" rules="required|confirmed:password" >
              <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
                <b-form-input
                  id="confirm-password"
                  v-model="confirmPassword"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldTypeRetype"
                  placeholder="*********"
                />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="passwordToggleIconRetype" @click="togglePasswordRetype"/>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="confirm-password">Motivo de cambio de contraseña</label>
            </div>
            <validation-provider #default="{ errors }" name="confirm password" rules="required" >
              <b-input-group :class="errors.length > 0 ? 'is-invalid':null" >
                <b-form-textarea
                  placeholder="Indique los motivos del cambio de contraseña"
                  rows="3"
                  :state="errors.length > 0 ? false:null"
                  v-model="comments"
                />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="float-right">
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1 mr-1" > Reset </b-button>
            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 " :disabled="invalid || isChangingPassword ">
              <span v-if="isChangingPassword"> <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" /> Changing password in process...</span>
              <span v-if="!isChangingPassword"> <feather-icon class="cursor-pointer" icon="SaveIcon" /> Save changes </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      comments: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isChangingPassword: false,
    }
  },
  computed: {
    ...mapState('auth',['user']),
    ...mapState('fivesClubCatalogs',['selectedOwnerPassword']),
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('fivesClubCatalogs',['changePasswordOwner']),

    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async handlerChangePassword(){
      this.isChangingPassword = true
      const payload = { idOwner: this.selectedOwnerPassword.id, newPassword: this.newPassword, notes: this.comments, idUser:this.user.idUser }
      const { status, message } =  await this.changePasswordOwner( payload )
      if(status){
        Object.assign(this.$data, this.$options.data())//limpio data o reset
        this.$refs.formChangePassword.reset();
				await showAlertMessage( 'Cambio de contraseña', 'InfoIcon', `${message} : EL propietario puede iniciar sesión con su nueva contraseña`, 'success', 4000, 'bottom-right')
      } else {
				showAlertMessage( 'Cambio de contraseña falló', 'XIcon', message, 'danger', 4000, 'bottom-right')
      }
      this.isChangingPassword = false
      this.$root.$emit("bv::hide::modal", "change-password-modal" + this.selectedOwnerPassword.id);
    }
  },
}
</script>

