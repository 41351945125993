<template>
  <b-card>
    <div class="d-flex justify-content-between mb-2">
      <span>
        <h3>Registrar nuevo propietario</h3>
      </span>
      <b-button size="sm" variant="warning" @click="goToHome"> <feather-icon icon="ArrowLeftIcon" size="16" />Regresar</b-button>
    </div>
    <b-overlay :show="isSavingOwners" rounded class="pl-2 pr-2">
      <ValidationObserver ref="createOwners" v-slot="{ invalid }">
        <b-form @submit.prevent="save" autocomplete="off">
          <b-tabs class="mb-1" v-model="tabIndex">
            <b-tab title="General" class="border rounded">
              <b-row class="mt-1 mb-1 p-1">
                <b-col md="12">
                  <div class="d-flex justify-content-between">
                    <h5>Datos del propietario </h5>
                    <!-- <div><b-button size="sm" variant="warning" @click="goToHome"> <feather-icon icon="ArrowLeftIcon" size="16" />Regresar</b-button></div> -->
                  </div>
                </b-col>

                <b-col md="">
                  <ValidationProvider name="código" rules="required">
                    <b-form-group label="Código*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="code"
                        :state="errors[0] || !valid ? false : true"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider name="prefix" rules="required">
                    <b-form-group label="Prefijo*" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] || !valid ? false : true"
                        v-model="prefix"
                      >
                        <option value="">Seleccione prefijo</option>
                        <option
                          v-for="namesPrefixIdList in namesPrefixId"
                          :key="namesPrefixIdList.id"
                          :value="namesPrefixIdList.id"
                        > {{ namesPrefixIdList.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="nombre" rules="required">
                    <b-form-group label="Nombre*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="name"
                        :state="errors[0] || !valid ? false : true"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="apellido" rules="required">
                    <b-form-group label="Apellido*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="lastname"
                        :state="errors[0] || !valid ? false : true"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="Fecha de Nacimiento" rules="">
                    <b-form-group label="Fecha de Nacimiento*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="date"
                        v-model="birthdate"
                        :state="errors[0] || !valid ? false : true"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="género" rules="required">
                    <b-form-group label="Género*" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] || !valid ? false : true"
                        v-model="gender"
                      >
                        <option value="">Seleccione Género</option>
                        <option
                          v-for="gendersList in namesGender"
                          :key="gendersList.id"
                          :value="gendersList.id"
                        > {{ gendersList.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="teléfono" rules="required">
                    <b-form-group label="Teléfono*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        autocomplete="off"
                        v-model="phone"
                        :state="errors[0] || !valid ? false : true"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="correo" rules="required|email">
                    <b-form-group label="Correo electrónico*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="email"
                        v-model="email"
                        @input="checkIfEmailClientExist(email)"
                        autocomplete="off"
                        :state="errors[0] || !valid ? false : true"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="pass" rules="required">
                    <b-form-group label="Contraseña*" slot-scope="{ valid, errors }">
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors[0] || !valid ? false : true"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          autocomplete="new-password"
                          name="password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <b-form-invalid-feedback v-for="(error, index) in errors" :key="index">{{ error }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

              </b-row>
              <hr>
              <b-row class="mt-1 mb-1 p-1">
                <b-col md="6">
                  <b-row>
                    <b-col md="6">
                      <ValidationProvider name="idioma" rules="required">
                        <b-form-group label="Idioma*" slot-scope="{ valid, errors }">
                          <b-form-select
                            :state="errors[0] || !valid ? false : true"
                            v-model="language"
                          >
                            <option value="" disabled>Seleccione Idioma</option>
                            <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                              {{ lang.name }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                      <ValidationProvider name="nacionalidad" rules="required">
                        <b-form-group label="Nacionalidad*" slot-scope="{ valid, errors }">
                          <b-form-select
                            :state="errors[0] || !valid ? false : true"
                            v-model="nacionality"
                          >
                            <option disabled value="">Seleccione Nacionalidad</option>
                            <option
                              v-for="nacionalityList in nationalities"
                              :key="nacionalityList.id"
                              :value="nacionalityList.id"
                            > {{ nacionalityList.name }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                      <ValidationProvider name="ciudad">
                        <b-form-group label="Ciudad" slot-scope="{ valid, errors }">
                          <b-form-input
                            class="form-control"
                            type="text"
                            v-model="city"
                            :state="errors[0] || !valid ? false : true"
                            @input="uppercaseText"
                          />
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                      <ValidationProvider  name="código postal">
                        <b-form-group label="Código postal" slot-scope="{ valid, errors }">
                          <b-form-input
                            class="form-control"
                            maxlength="10"
                            @keypress="onlyNumbersLetters"
                            v-model="zip"
                            :state="errors[0] || !valid ? false : true"
                          />
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col md="6">
                  <ValidationProvider name="direccion">
                    <b-form-group label="Direccion" slot-scope="{ valid, errors }">
                      <b-form-textarea
                        class="form-control"
                        v-model="adress"
                        :state="errors[0] || !valid ? false : true"
                        rows="4"
                        max-rows="4"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <hr>
              <b-row class="mt-1 mb-1 p-1" align-v="center" >
                <b-col md="3">
                  <ValidationProvider name="tipo" rules="" >
                    <b-form-group label="Tipo*" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] || !valid ? false : true"
                        v-model="typeOwner"
                        @change="description=null"
                      >
                        <option :value="null">Seleccione tipo</option>
                        <option
                          v-for="tipo in ownerType"
                          :key="tipo.id"
                          :value="tipo.id"
                        > {{ tipo.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3" v-if="typeOwner == 3">
                  <ValidationProvider name="Descripción" rules="">
                    <b-form-group label="Descripción" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="description"
                        :state="errors[0] || !valid ? false : true"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <div class="float-rigth mt-1">
                    <b-form-checkbox
                      v-model="status"
                      :value="true"
                      class="custom-control-success"
                    > Activo
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col md="3">
                  <div class="float-rigth mt-1">
                    <b-form-checkbox
                      v-model="canBook"
                      class="custom-control-success"
                    > Puede reservar
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Viviendas" class="border rounded">
              <b-row class="mt-1 mb-1 p-1">
                <b-col md="12">
                  <div class="d-flex justify-content-between">
                    <h5>Filtro de viviendas para el propietario  </h5>
                  </div>
                </b-col>
                <b-col md="4">
                  <validation-provider name="resort" rules="">
                    <b-form-group slot-scope="{ valid, errors }" label="Resort">
                      <b-form-select
                        :state="errors[0] || !valid ? false : null"
                        v-model="selectedResort"
                        :clearable="false"
                      >
                        <option value="null" disabled>Seleccione una opción</option>
                        <option v-for="h in hotels" :key="h.id" :value="h.id">
                          {{ h.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider name="vivienda" rules="">
                    <b-form-group slot-scope="{ valid, errors }" label="Vivienda" >
                      <b-form-input
                        type="number"
                        :state="errors[0] || !valid ? false : null"
                        v-model="selectedVivienda"
                        placeholder="Inserte una vivienda"
                      />
                      <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4" v-if="!isLoadingHousing">
                  <div class="d-flex justify-content-start">
                    <b-form-group label="Acciones">
                      <b-button variant="warning" @click="resetHousing()" class="mr-1" :disabled="!housingList.length > 0 ">Reset </b-button>
                      <b-button variant="primary" @click="searchByFilter()" >Buscar </b-button>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <div class="text-center mt-2" v-if="isLoadingHousing">
                    <b-spinner style="width: 3rem; height: 3rem;" /><br>
                    <strong>Cargando</strong>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-1 mb-1 p-1" v-if="housingList.length">
                <b-col md="12">
                  <div class="d-flex justify-content-between">
                    <h4>Viviendas disponibles</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <b-input v-model="filter" placeholder="Buscar en tabla" class="mb-2"></b-input>
                </b-col>
                <b-col md="12" >
                  <ValidationObserver ref="housingListForm" :disabled="true" >
                    <b-table
                      responsive
                      sticky-header="400px"
                      :filter="filter"
                      :items="housingList"
                      :fields="fields"
                      class="mb-0"
                      :filter-included-fields="filterOn"
                    >
                      <!-- Optional default data cell scoped slot -->
                      <template #cell(percentage)="data">
                        <ValidationProvider :name="`porcentaje vivienda ${(data.index+1)}`" :rules="`required_if:add_vivienda_${(data.index+1)},true|max_value:100`">
                          <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                            <b-form-input
                              class="form-control"
                              type="number"
                              step="0.01"
                              min="0.00"
                              :state="errors[0] || !valid ? false : null"
                              max="1000.00"
                              v-model="data.item.percent"
                            ></b-form-input>
                            <b-form-invalid-feedback class="replacePercent"> {{ errors[0] }} </b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </template>

                      <template #cell(housingType)="data">
                        <ValidationProvider :name="`Tipo vivienda ${(data.index+1)}`" :rules="`required_if:add_vivienda_${(data.index+1)},true`">
                          <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                            <b-form-select
                              v-model="data.item.housingType"
                              :state="errors[0] || !valid ? false : null"
                            >
                              <option :value="null">Seleccione Tipo de Propietario</option>
                              <option
                                v-for="idHousingList in idHousing"
                                :key="idHousingList.id"
                                :value="idHousingList.id"
                              > {{ idHousingList.name }}
                              </option>
                            </b-form-select>
                            <b-form-invalid-feedback class="replaceType"> {{ errors[0] }} </b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </template>

                      <template #cell(contract)="row">
                        <div>
                          <label v-if="!!row.item.contract || !!row.item.membershipcode">
                            <span class="font-weight-bold">{{ row.item.contract || '-'}}</span> <br>
                            <span class="font-weight-bold">{{ row.item.membershipcode || '-'}}</span>
                          </label>
                          <span v-else>-</span>
                        </div>
                      </template>

                      <template #cell(status)="data">
                        <div>
                          <ValidationProvider :name="`add_vivienda_${(data.index+1)}`">
                            <b-form-checkbox
                              v-model="data.item.state"
                              :value="true"
                              switch
                              inline
                              :unchecked-value="false"
                              class="custom-control-success"
                            ></b-form-checkbox>
                          </ValidationProvider>
                        </div>
                      </template>
                    </b-table>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-button type="reset" variant="warning" class="mr-2"> Reset</b-button>
                <b-button type="submit" variant="primary" :disabled="invalid || isSavingOwners">
                  <b-spinner small v-if="isSavingOwners" /> Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { showAlertMessage, sweetAlert } from '@/helpers/helpers'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { idHousing, ownerType } from '@/data/fivesClub';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { createArraysToSaveCustomers } from '@/helpers/fivesClubHelper'
import { namesGender, namesPrefixId } from "@/data/data";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
export default {
  mixins: [togglePasswordVisibility, utils],
  components: {
    vSelect,
    flatPickr,
  },
  async mounted() {
    await this.init()
  },
  data() {
    return {
      idHousing,
      ownerType,
      name: "",
      lastname: "",
      email: null,
      code: '',
      password: '',
      phone: '0000000000',
      prefix: 1,
      typeOwner: null,
      description: null,
      gender: "",
      nacionality: "",
      language: "",
      password: '',
      status: true,
      adress: "",
      city: "",
      zip: "",
      birthdate: null,
      canBook: false,
      photo: null,
      isLoadingHousing: false,
      beforeSearch: false,
      subcondosfiltered: null,
      selectedResort: null,
      selectedVivienda: null,
      optionsHousing: [],
      housingList: [],
      namesGender,
      namesPrefixId,
      isSavingOwners: false,
      filter: "",
      tabIndex: 0,
      fields: [
        { key: "ownername", label: "Propietario", class: "text-center font-weight-bold font-small" },
        { key: "contract", label: "Contrato", class: "text-center" },
        { key: "fullname", label: "Nombre", class: "text-center", thClass: "w-25" },
        { key: "percentage", label: "Porcentaje", class: "text-center", thClass: "w-25" },
        { key: "housingType", label: "Tipo", class: "text-center", thClass: "w-25" },
        { key: "status", label: "Agregar a lista", class: "text-center" },
      ],
      isHovered: false,
      filterOn: ["fullname"]
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['nationalities', 'condos', 'subCondos', 'requestOwners']),
    ...mapState('auth', ['user']),
    ...mapState("start", ["languages", "hotels"]),
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['saveOwners', 'fetchOwners', 'checkIfClientExists', 'fetchHousingOptions', 'getInitialContentOwners']),
    ...mapMutations('fivesClubCatalogs', ['setOwners', 'setSelectedOwner']),
    async init(){
      this.isLoadingOwner = true
      await this.getInitialContentOwners({
          hotels: !this.hotels?.length > 0,
          languages: !this.languages?.length > 0,
          nationalities: !this.nationalities?.length > 0,
        }) // aqui pido hotels, languages y nationalities

      this.isLoadingOwner = false
    },
    async save() {
      const tableNewHousing = this.$refs?.housingListForm
      const validHousingData = tableNewHousing ? await tableNewHousing.validate() : true

      if (!validHousingData) {
        this.tabIndex = 1
        return false
      }

      const adressesList = {
        adress: !!this.adress ? this.adress : null,
        city: !!this.city ? this.city : null,
        zip: !!this.zip ? this.zip : null,
      };
      const housingList = createArraysToSaveCustomers(this.housingList.filter(house=> !!house.state))

      this.isSavingOwners = true
      const payload = {
        idUser: this.user.idUser,
        name: this.name,
        lastname: this.lastname,
        email: this.email,
        code: this.code,
        password: this.password,
        phone: this.phone,
        prefix: this.prefix,
        gender: this.gender,
        nacionality: this.nacionality,
        language: this.language,
        adresses: adressesList,
        housing: housingList,
        photo: null,
        status: Boolean(this.status),
        canBook: this.canBook,
        typeOwner: this.typeOwner,
        Description: this.description,
        birthdate: Boolean(this.birthdate) ? this.birthdate : null,
      }

      const respuesta = await this.saveOwners(payload) // saving in backend
      if ( respuesta?.status ) {
        showAlertMessage( "Ok: proceso finalizado", "InfoIcon", respuesta?.message, "success", 4000, "bottom-right" )
        Object.assign(this.$data, this.$options.data())
        this.$refs.createOwners.reset() //reset form
        this.isSavingOwners = false
        this.$router.push({ name: "customers-edit", params: { idOwner: respuesta?.data?.idOwner } })
      }
      if(!respuesta?.status){
        // console.log(respuesta.response.data.message)
        this.isSavingOwners = false
        return showAlertMessage( "Error: proceso finalizado", "InfoIcon", `${respuesta?.response.data.message}`, "danger", 4000, "bottom-right" )
      }
      this.isSavingOwners = false
    },
    async checkIfEmailClientExist(email) {
      //eslint-disable-next-line
      const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (reg.test(email)) {
        const info = await this.checkIfClientExists(email) // updateBlackout in backend
        if (info.id) {
          const swalWithBootstrapButtons = this.$swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          })

          swalWithBootstrapButtons.fire({
            title: `<p style="font-size:.7em;">Este cliente ya existe: <br> ${info.name} ${info.lastname} </p>`,
            text: "¿Desea actualizar este cliente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "customers-edit", params: { idOwner: info.id } })
            } else this.email = null
          })
        }
      }
    },
    chooseSubCondo(condo){
      this.subcondosfiltered = this.subCondos.filter(item => item.condos === condo)
    },
    async searchByFilter(){
      if(!this.selectedResort) return showAlertMessage( 'Seleccione hotel', 'InfoIcon', 'Seleccione un hotel para continuar', 'warning', 4000, 'bottom-right' )
      const payload = {
        resort: this.selectedResort,
        Number: this.selectedVivienda,
        CreateOwner: true,
      }
      // console.log(payload)
      this.beforeSearch = false
      this.isLoadingHousing = true
      const listHousing = await this.fetchHousingOptions(payload)
      const formatedHousing = listHousing.map(house => {
        return {...house, state: false, percent: null, housingType: null}
      })
      this.housingList = this.housingList.filter(h=>!!h.state && !formatedHousing.map(v=>v.id).includes(h.id)).concat(formatedHousing)

      this.beforeSearch = true
      this.isLoadingHousing = false
    },
    goToHome(){
      this.$router.push({ name: 'customers' })
    },
    resetHousing() {
      this.housingList = []
      this.selectedResort = null
      this.selectedVivienda = null
    },
    uppercaseText(){
      this.name = this.name.toUpperCase()
      this.lastname = this.lastname.toUpperCase()
      this.city = this.city.toUpperCase()
      this.adress = this.adress.toUpperCase()
    }
  },
};
</script>
<style lang="scss">
.table-responsive th{
  padding:10px 20px 20px 10px!important;
  font-size:12px;
}
.table-responsive td{
  padding: 5px!important;
  font-size: 12px;
}
.custom-control-success.custom-control.custom-checkbox {
  margin: 0 auto;
  display: inline;
}

.replacePercent{
  visibility: hidden;
  position: relative;
}
.replacePercent:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "El campo Porcentaje es obligatorio.";
}
.replaceType{
  visibility: hidden;
  position: relative;
}
.replaceType:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "El campo Tipo es obligatorio.";
}
</style>