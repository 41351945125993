<template>
  <div>
    <div>
      <div class="d-flex justify-content-between">
        <h4>Filtros</h4>
        <div v-if="owners.length">
          <b>Total de propietarios: </b> <b-badge variant="success" >  {{owners.length}} </b-badge>
        </div>
      </div>
      <validation-observer tag="form" ref="filterOwners"  v-slot="{ invalid }" >
        <b-form @submit.prevent="searchByFilter">
          <b-row>
            <b-col md="2">
              <validation-provider name="filtro" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Tipo de filtro*" >
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedFilter"
                    @change="resetFiltrado"
                  >
                    <option :value="null">Selecciona filtro</option>
                    <option v-for="owners in filtro" :key="owners.id" :value="owners.value" > {{ owners.name }} </option>
                  </b-form-select>
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4" v-if="selectedFilter !== 'Resort'">
              <validation-provider name="filtro" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Filtro">
                  <b-form-input
                    v-model="Filtrado"
                    :state="errors[0] ? false : valid ? true : null"
                    placeholder="Filtrar por..."
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2" v-if="selectedFilter === 'Resort'">
              <validation-provider name="resort" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Resort">
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedResort"
                    :clearable="false"
                  >
                    <option value="null" disabled>Seleccione una opción</option>
                    <option v-for="h in hotels" :key="h.id" :value="h.id">
                      {{ h.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2" v-if="selectedFilter === 'Resort'">
              <validation-provider name="vivienda" rules="">
                <b-form-group slot-scope="{ valid, errors }" label="Vivienda" >
                  <b-form-input
                    type="number"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedVivienda"
                    placeholder="Inserte una vivienda"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="">
              <div class="mt-custom">
                <b-button variant="warning" :disabled="invalid " class="mr-1" @click="resetFilterOwners"> Reset </b-button>
                <b-button
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="invalid"
                > Buscar
                </b-button>
              </div>
            </b-col>
            <b-col md="" v-if="can('fivesclub_owners_show_owners_create_buttons')">
              <div class="mt-custom">
                <b-button
                  :to="{name: 'customers-create'}"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="float-right"
                  v-b-tooltip.hover.left="'Dar de alta propietario'"
                > <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <hr class="mb-4" v-if="owners.length">
    <b-row v-if="owners.length">
      <b-col md="2">
        <label>Numero de registros</label>
        <v-select
          v-model="porPagina"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector"
          @input="changePerPage"
        />
      </b-col>
      <b-col md="4">
        <label>Buscar en tabla</label>
        <div class="d-flex">
          <b-form-input
            v-model="filter.queryCustomers"
            class="mr-1"
            placeholder="Buscar..."
            type="search"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { filtroOwners } from "@/data/fivesClub";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  directives: {
    Ripple,
  },
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      subcondosfiltered: null,

      porPagina: this.perPage,
      statusFilter: null,

      filtro: filtroOwners,
      Filtrado: null,
      selectedFilter: null,
      selectedResort: null,
      selectedVivienda: '',
      condoInfoRequired: false
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['filter', 'condos', 'subCondos','owners' ]),
    ...mapState('start',['hotels']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs', ['setQueryFilter','setOwners']),
    changePerPage(perPage) {
      this.$emit('change-per-page', perPage);
      this.porPagina = perPage;
    },
    resetFiltrado(){
      this.Filtrado = null
      this.selectedResort = null
      this.selectedVivienda = null
    },
    resetFilterOwners(){
      this.setOwners( [] )
      Object.assign(this.$data, this.$options.data())
      this.$refs.filterOwners.reset()
      this.porPagina = 10
    },
    searchByFilter() {
      let payload = {}

      if (this.selectedFilter === 'Resort' ) {
        payload = {
          IdResorts: this.selectedResort,
          Number: !!this.selectedVivienda ? this.selectedVivienda : null
        }
      } else payload[this.selectedFilter] = this.Filtrado

      this.$emit("look-per-filter", payload);
    },
    chooseSubCondo(condo) {
      this.subcondosfiltered = this.subCondos.filter(
        (item) => item.condos === condo
      );
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}

.paddler {
  padding-left: 1rem;
}
.mt-custom {
  margin-top: 2rem;
}
</style>